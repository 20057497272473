import React from "react";
import { Link } from "react-router-dom";

export const CallToAction: React.FC = () => {
  return (
    <div className="bg-slate-50">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 sm:px-6 sm:py-10 lg:px-8">
        <div className="relative isolate overflow-hidden bg-white px-6 pt-16  sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <div className="max-w-lg text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl  font-bold tracking-tight text-blue-700 sm:text-4xl">
              Get started now
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600	">
              Get the best home care services for elders in the region!
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <Link
                to="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=69bb539f-4889-4e0d-b7d7-dfa271a29edf&ccId=19000101_000001&lang=en_US"
                className="rounded-md bg-emerald-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </Link>
              <Link
                to="#"
                className="text-sm font-semibold leading-6 text-white"
              >
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src={
                "https://api.chautarihomecare.com/wp-content/uploads/2023/09/homepage_banner_1-scaled.jpg"
              }
              alt="App screenshot"
              width={1824}
              height={1080}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
