import { Layout } from "../components/common/Layout";
import { Hero } from "../components/Hero";
//import { NewsLetter } from "../components/common/NewsLetter";

export default function CareersPage() {
  return (
    <Layout>
      <Hero
        title="Jobs at Chautari Home Care"
        imageUrl="https://api.chautarihomecare.com/wp-content/uploads/2023/09/careers-scaled.jpg"
      />
      <article className="mx-auto max-w-7xl pb-20">
        <div className="grid md:grid-cols-2 gap-4">
          {/* Left column */}
          <div className="py-5 px-5 md:p-10">
            <p className="md:py-20 text-3xl sm:text-5xl font-bold tracking-wide leading-relaxed">
              Jobs Listings
            </p>
            <p className="text-muted text-sm text-gray-600">
              No jobs available right now!
            </p>
          </div>
          {/* Right column */}
          <div className="p-5 md:py-10">
            <p className="py-5 md:py-20 text-lg text-muted">
              We are always looking for compassionate and caring individuals to
              join our team. If you are interested in joining our team, please
              fill out the form below and we will get back to you as soon as
              possible.
            </p>
          </div>
        </div>
      </article>
    </Layout>
  );
}
