import { createBrowserRouter } from "react-router-dom";

import { HomePage, AboutPage, ServicesPage, CareersPage } from "./pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "about",
    element: <AboutPage />,
  },
  {
    path: "services",
    element: <ServicesPage />,
  },
  {
    path: "careers",
    element: <CareersPage />,
  },
]);
