import React from "react";
import { Layout } from "../components/common/Layout";
import { Hero } from "../components/Hero";
import {
  ClockIcon,
  HeartIcon,
  ShieldExclamationIcon,
  SunIcon,
} from "@heroicons/react/24/outline";
//import { NewsLetter } from "../components/common/NewsLetter";

export default function ServicesPage() {
  return (
    <Layout>
      <Hero
        title="Services"
        imageUrl="https://api.chautarihomecare.com/wp-content/uploads/2023/09/services_cover-scaled-e1694810609807.jpg"
      />
      <article className="mx-auto max-w-7xl pb-20">
        <div className="grid md:grid-cols-2 gap-4">
          {/* Left column */}
          <div className="py-5 px-5 md:p-10">
            <p className="md:py-20 text-3xl md:leading-relaxed sm:text-5xl font-bold tracking-wide  space-y-3">
              Do you prefer staying at home over visiting a{" "}
              <span className="text-red-600">healthcare facility?</span>
            </p>
          </div>
          {/* Right column */}
          <div className="p-5 md:py-10">
            <p className="py-5 md:py-20 text-lg text-muted">
              Chautari Home Care prioritizes building meaningful relationships
              to assist caregivers, preserve the dignity of care recipients, and
              improve the overall quality of life for seniors and their
              families.
            </p>
            <p className="text-lg text-muted">
              Chautari Home Care provides exceptional round-the-clock,
              year-round in-home care services to individuals in need. We take
              pride in our compassionate home care program, which is tailored to
              empower individuals to remain in the comfort of their homes and
              maintain their well-being for an extended period.
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-4 gap-3 ">
          <div className="bg-white rounded-lg shadow-lg p-4 px-3">
            <div className="text-center">
              <div className="hover:bg-green-300 bg-teal-50 p-4 rounded-full mb-5 flex flex-col items-center">
                <HeartIcon className="w-20 h-20 text-green-600 hover:text-white" />
              </div>
              <p className="mt-2 font-bold text-xl">Advanced Care</p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4 px-3">
            <div className="text-center">
              <div className="hover:bg-green-300 bg-teal-50 p-4 rounded-full mb-5 flex flex-col items-center">
                <SunIcon className="w-20 h-20 text-green-600 hover:text-white" />
              </div>
              <p className="mt-2 font-bold text-xl">Daily Homecare</p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4 px-3">
            <div className="text-center">
              <div className="hover:bg-green-300 bg-teal-50 p-4 rounded-full mb-5 flex flex-col items-center">
                <ClockIcon className="w-20 h-20 text-green-600 hover:text-white" />
              </div>
              <p className="mt-2 font-bold text-xl">Hourly Care</p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4 px-3">
            <div className="text-center">
              <div className="hover:bg-green-300 bg-teal-50 p-4 rounded-full mb-5 flex flex-col items-center">
                <ShieldExclamationIcon className="w-20 h-20 text-green-600 hover:text-white" />
              </div>
              <p className="mt-2 font-bold text-xl">Respite Care</p>
            </div>
          </div>
        </div>
      </article>
      {/* <div className="bg-white">
        <NewsLetter title="" />
      </div> */}
    </Layout>
  );
}
