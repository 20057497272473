import React from "react";

import { Layout } from "../components/common/Layout";
import { IndexHero } from "../components/Hero";
import { CallToAction, WhoWeAre } from "../components/Home";

export default function HomePage() {
  return (
    <Layout>
      <IndexHero />
      <WhoWeAre />
      <CallToAction />
    </Layout>
  );
}
