import React from "react";
import { Link } from "react-router-dom";

const imageLink =
  "https://api.chautarihomecare.com/wp-content/uploads/2023/09/homepage_banner-scaled.jpg";
export const IndexHero: React.FC = () => {
  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 md:px-8">
        <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56  xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <div className="hidden sm:mt-32 sm:flex lg:mt-16">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Welcome to Chautari Homecare{" "}
                <Link
                  to="/about"
                  className="whitespace-nowrap font-semibold text-indigo-600"
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
            <h1 className="mt-5 xs:mt-10 font-bold tracking-wide text-blue-600 sm:mt-10 text-3xl md:text-5xl">
              Caring Beyond
              <span className="block mt-4">Where Hearts Find Rest.</span>
            </h1>
            <p className="mt-4 text-lg leading-8 text-gray-600">
              Beneath the Tree of Compassion.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                to="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=69bb539f-4889-4e0d-b7d7-dfa271a29edf&ccId=19000101_000001&lang=en_US"
                className="rounded-md bg-emerald-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get hired
              </Link>
              <Link
                to="/services"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-auto"
            src={imageLink}
            alt="Home page banner"
            width={1824}
            height={1080}
          />
        </div>
      </div>
    </div>
  );
};
