import React from "react";

import {
  MapPinIcon,
  RocketLaunchIcon,
  ClockIcon,
  HeartIcon,
  LanguageIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Based in Pennsylvania",
    description:
      "We are locally owned and operated leading home care agency in the state of Pennsylvania.",
    icon: MapPinIcon,
  },
  {
    name: "Care beyond walls",
    description:
      "Our staff members are trained and qualified who are dedicated in developing your personalized care plan and offer timely services.",
    icon: RocketLaunchIcon,
  },
  {
    name: "Around the clock",
    description: "24/7 on call service led by caring hearts.",
    icon: ClockIcon,
  },
  {
    name: "Caring hearts",
    description:
      "We are committed to providing compassionate care, your safety is our priority, and our services are designed keeping participants at the center.",
    icon: HeartIcon,
  },
  {
    name: "Diverse team",
    description:
      "Our team is diverse, and we serve all populations offering culturally and linguistically appropriate services.",
    icon: LanguageIcon,
  },
  {
    name: "Compliance ready",
    description:
      "We comply with all applicable federal, state, and local laws.",
    icon: ShieldCheckIcon,
  },
];
export const WhoWeAre: React.FC = () => {
  return (
    <div className="bg-white pb-4">
      <div className="mx-auto max-w-full px-6 lg:px-8">
        <div className="mx-auto max-w-4xl lg:text-center">
          <h2 className="mb-2 text-3xl font-bold tracking-wide text-blue-700 sm:text-4xl">
          
            Chautari Home Care
            <svg className="absolute bottom-0 left-0" width="100%" height="10" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="0" x2="100%" y2="0" stroke="blue" strokeWidth="5" transform="skewY(-10)"/>
  </svg>
          </h2>
          <p className="text-lg font-bold tracking-wide text-blue-500 sm:text-xl">
            Dedicated to wellness, Commited to care
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
            Rooted in Pennsylvania, Chautari Home Care stands tall as your
            partner in wellbeing. Our team, seasoned with diverse skills and
            years of dedication, is all about improving lives.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
            In the world of caregiving, where every step matters, Chautari Home
            Care redefines the norm. We put connections first nurturing
            caregivers, preserving dignity, and magnifying moments for seniors
            and families.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
            Around the clock, every day, Chautari Home Care offers top-tier
            in-home services, because care knows no clock. Our pride shines
            through our compassionate home care program, tailored to empower
            individuals to embrace their own haven, promoting lasting wellness.
            <br />
            <span className="text-blue-500 hover:text-blue-800">
              Join Chautari Home Care today, and let us walk this meaningful
              journey together.
            </span>
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-500">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600 text-justify">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
